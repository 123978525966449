<template>
  <div>
    <div style="padding: 5em 50px 10px">
      <h3 class="uk-text-background">集団分析</h3>
      <hr />
    </div>
    <div v-show="$store.state.common.sIsLoading">Loading...</div>
    <div
      v-show="
        !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
      "
      style="margin: 0 20px"
    >
      <div style="width: 100%">
        <div style="width: 100%; text-align: left">
          <label style="color: #a9a9a9"
            ><small>{{ $store.state.sc_config.sLblWorkGroup }}</small></label
          >
        </div>
        <div style="width: 100%; text-align: center">
          <div
            style="
              height: 400px;
              padding: 5px;
              box-sizing: border-box;
              border-radius: 10px;
              background-color: #d3d3d3;
            "
          >
            <div style="width: 45%; height: 400px; float: left">
              <div
                style="
                  display: inline-block;
                  width: 45%;
                  padding: 5px;
                  box-sizing: border-box;
                  background-color: #f5f5f5;
                  border: solid 1px #696969;
                  border-radius: 10px;
                  height: 350px;
                  overflow: scroll;
                "
              >
                <div style="display: inline-block; width: 100%">
                  <span style="color: #a9a9a9">職場情報</span>
                </div>
                <div style="display: inline-block; width: 100%">
                  <ul style="list-style-type: none">
                    <li v-for="tWp in vWpListDataForDisplay" :key="tWp.wp_id">
                      <button
                        type="button"
                        class="operate-button"
                        style="
                          width: 50%;
                          line-height: 3;
                          background-color: #ffffff;
                          border-radius: 10px;
                          border: solid 1px #696969;
                          color: #696969;
                        "
                        @click="doMoveToRightList(tWp.busyo_id)"
                      >
                        {{ tWp.busyo_name }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                style="
                  display: inline-block;
                  width: 45%;
                  padding: 5px;
                  box-sizing: border-box;
                  background-color: #f5f5f5;
                  border: solid 1px #696969;
                  border-radius: 10px;
                  height: 350px;
                  overflow: scroll;
                "
              >
                <div style="display: inline-block; width: 100%">
                  <span style="color: #a9a9a9">グループ候補</span>
                </div>
                <div style="display: inline-block; width: 100%">
                  <ul style="list-style-type: none">
                    <li
                      v-for="tWp in vWpListDataCandidateGroup"
                      :key="tWp.wp_id"
                    >
                      <button
                        type="button"
                        class="operate-button"
                        style="
                          width: 50%;
                          line-height: 3;
                          background-color: #ffffff;
                          border-radius: 10px;
                          border: solid 1px #696969;
                          color: #696969;
                        "
                        @click="doMoveToLeftList(tWp.busyo_id)"
                      >
                        {{ tWp.busyo_name }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              style="
                width: 10%;
                height: 350px;
                line-height: 350px;
                float: left;
                vertical-align: middle;
              "
            >
              <div style="display: inline-block; width: 100%">
                <button
                  type="button"
                  class="operate-button"
                  style="
                    width: 50%;
                    line-height: 3;
                    background-color: #ffffff;
                    border-radius: 10px;
                    border: solid 1px #696969;
                    color: #696969;
                  "
                  @click="doCreateAnalysisGroup"
                >
                  <b>＞</b>
                </button>
              </div>
            </div>
            <div style="width: 45%; height: 400px; float: left">
              <div
                style="
                  display: inline-block;
                  width: 100%;
                  padding: 5px;
                  box-sizing: border-box;
                  background-color: #f5f5f5;
                  border: solid 1px #696969;
                  border-radius: 10px;
                  height: 350px;
                  overflow: scroll;
                "
              >
                <div style="display: inline-block; width: 100%">
                  <span style="color: #a9a9a9">グループ</span>
                </div>
                <div style="display: inline-block; width: 100%">
                  <ul
                    v-for="(tGrp, idx) in vAnalysisGroupList"
                    :key="idx"
                    style="
                      width: 40%;
                      display: inline-block;
                      list-style-type: none;
                    "
                  >
                    <div
                      style="
                        background-color: #ffffff;
                        border: solid 1px #a9a9a9;
                        border-radius: 10px;
                      "
                    >
                      <div>
                        <b style="color: #696969">＜グループ{{ idx + 1 }}＞</b>
                        <span
                          class="uk-button-secondary"
                          style="border-radius: 20px"
                          uk-icon="close"
                          @click="doReleaseAnalysisGroup(idx)"
                        ></span>
                      </div>
                      <li
                        v-for="tGrpWp in tGrp"
                        :key="tGrpWp.wp_id"
                        style="border-radius: 10px; color: #696969"
                      >
                        ・{{ tGrpWp.busyo_name }}
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="
          uk-container
          uk-flex
          uk-flex-center
          uk-flex-middle
          uk-margin-top
          uk-margin-bottom
        "
      >
        <div class="uk-width-1-3">
          <button
            type="button"
            @click="$router.back()"
            class="uk-button uk-button-default uk-width-1-1"
          >
            &lt;&nbsp;戻る
          </button>
        </div>
        <div class="uk-width-1-3 uk-margin-small-left">
          <button
            type="button"
            @click="send_data"
            class="uk-button uk-button-primary uk-width-1-1"
          >
            登録
          </button>
        </div>
      </div>

      <!-- <button type="button" name="button" @click="set_grouping">グループ化</button> -->
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      do_something: true,
      vAgListData: [],
      vWpListData: [],
      vWpListDataForDisplay: [], // 職場情報（分析グループ作成用）
      vWpListDataCandidateGroup: [], // 職場分析グループ一時保持用
      vAnalysisGroupList: [], // 分析グループリスト
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "集団分析";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    this.checkLogined();
    this.get_info_busyo();
    // this.doFilterAnalysisGroupForDisplay()
    this.$store.state.common.sDataLoading = false;
  },
  methods: {
    // 分析グループを表示用に整形
    doFilterAnalysisGroupForDisplay() {
      this.vAnalysisGroupList = [];
      console.log(this.vWpListData);
      this.vWpListDataForDisplay = this.vWpListData.slice();
      console.log(this.vWpListDataForDisplay);
      // 一覧で選択した行の実施IDで絞る
      var filterListData = this.vAgListData.filter(
        (item) => item.sc_id == this.vScId
      );
      var i = 0;
      var tmpAgCode = "";
      var tmpArray = [];
      if (filterListData.length > 0) {
        for (i = 0; i < filterListData.length; i++) {
          // 分析グループ番号ごとに部署をまとめる
          if (
            tmpAgCode != "" &&
            filterListData[i].analysis_group_code != tmpAgCode
          ) {
            this.vAnalysisGroupList.push(tmpArray);
            tmpArray = [];
          }
          tmpArray.push(filterListData[i]);
          tmpAgCode = filterListData[i].analysis_group_code;
          // 部署情報リストから分析グループにある部署を除外する
          this.vWpListDataForDisplay = this.vWpListDataForDisplay.filter(
            (item) => item.wp_id != filterListData[i].wp_id
          );
        }
        if (tmpArray.length > 0) {
          this.vAnalysisGroupList.push(tmpArray);
        }
      }
    },
    // 分析グループ（右のリストへ反映）
    doMoveToRightList(wpId) {
      var i = 0;
      var tmpArray = [];
      for (i = 0; i < this.vWpListDataForDisplay.length; i++) {
        if (this.vWpListDataForDisplay[i].busyo_id == wpId) {
          tmpArray = this.vWpListDataForDisplay[i];
          this.vWpListDataCandidateGroup =
            this.vWpListDataCandidateGroup.concat(tmpArray);
          this.vWpListDataForDisplay.splice(i, 1);
          break;
        }
      }
      this.vWpListDataForDisplay.sort((a, b) => a.busyo_id - b.busyo_id);
      this.vWpListDataCandidateGroup.sort((a, b) => a.busyo_id - b.busyo_id);
    },
    // 分析グループ（左のリストへ反映）
    doMoveToLeftList(wpId) {
      var i = 0;
      var tmpArray = [];
      for (i = 0; i < this.vWpListDataCandidateGroup.length; i++) {
        if (this.vWpListDataCandidateGroup[i].busyo_id == wpId) {
          tmpArray = this.vWpListDataCandidateGroup[i];
          this.vWpListDataForDisplay =
            this.vWpListDataForDisplay.concat(tmpArray);
          this.vWpListDataCandidateGroup.splice(i, 1);
          break;
        }
      }
      this.vWpListDataForDisplay.sort((a, b) => a.busyo_id - b.busyo_id);
      this.vWpListDataCandidateGroup.sort((a, b) => a.busyo_id - b.busyo_id);
    },
    // グループ化
    doCreateAnalysisGroup() {
      if (this.vWpListDataCandidateGroup.length > 0) {
        this.vAnalysisGroupList.push(this.vWpListDataCandidateGroup);
        this.vWpListDataCandidateGroup = [];
      }
    },
    // グループ解除
    doReleaseAnalysisGroup(idx) {
      var tmpArray = [];
      tmpArray = this.vAnalysisGroupList[idx];
      this.vWpListDataForDisplay = this.vWpListDataForDisplay.concat(tmpArray);
      this.vWpListDataForDisplay.sort((a, b) => a.busyo_id - b.busyo_id);
      this.vAnalysisGroupList.splice(idx, 1);
    },
    checkLogined() {
      if (!this.$store.state.login_status_c) {
        this.$router.replace("/client-login");
      }
    },
    get_info_busyo() {
      this.$axios
        .post("/client/check_info_busyo", {
          alias_id: this.$store.state.alias_id,
          email: this.$store.state.email,
          pw: this.$store.state.pass,
        })
        .then(
          function (response) {
            //eslint-disable-line
            if (response.data.flg) {
              if (response.data.return_data_ag) {
                this.vAgListData = response.data.return_data_ag;
              }
              this.vWpListData = response.data.busyo;
              this.vWpListDataForDisplay = response.data.busyo;
            } else {
              this.flg = false;
              alert(
                "エラーが発生しました。\nお手数ですがもう一度やり直してください。"
              );
              this.$router.push("/error");
            }
            this.$store.state.common.sDataLoading = false;
          }.bind(this)
        )
        .catch(
          function (error) {
            console.log(error);
          }.bind(this)
        );
      this.$store.state.common.sDataLoading = false;
    },
    send_data() {
      this.$axios
        .post("/client/set_analysis_group_code", {
          alias_id: this.$store.state.alias_id,
          email: this.$store.state.email,
          pw: this.$store.state.pass,
          sc_id: this.$store.state.sc_id,
          analysis_group: this.vAnalysisGroupList,
        })
        .then((response) => {
          console.log(response);
          if (response.data.flg) {
            this.$store.state.grouping = true;
            this.$router.push("/client-target_selection");
          } else {
            alert("エラー");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
